header {
  height: 65vh;
  padding-top: 5rem;
  overflow: hidden;
}

.header__container {
  text-align: left;
  height: 100%;
  position: relative;
}

/* =================== NAME DISPLAY  =================== */
.name-display {
  display: inline-block;
  font-style:italic;
  font-size: 120px;
}

.first_name {
  display: block;
}

.last_name {
  display: block;
  margin-left: 30px;
  margin-top: -30px;
}


/* =================== CTA =================== */
.cta {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: left;
}

.download-icon {
  vertical-align: bottom;
  line-height: 1.40;
  margin-left: 10px;
}

/* =================== SOCIALS =================== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  right: 0;
  top: 2rem;
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 6rem;
  background: var(--color-primary);
  margin-top: 2rem;
}

/* =================== ME =================== */
/* .me {
  width: 24rem;
  height: 32rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 2rem;
  border-radius: 12rem 12rem 5rem 5rem;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
} */

/* =================== SCROLL DOWN =================== */

/* .scroll__down {
  position: absolute;
  right: -2.3rem;
  /* bottom: 0rem; */
  /* top: 15rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
} */

/* ============== MEDIA QUERIES (MED) ==============  */
@media screen and (max-width: 1024px) {
  header {
    /* height: 65vh; */
    display: unset;
  }
}

/* ============== MEDIA QUERIES (SM) ==============  */
@media screen and (max-width: 600px) {
  header {
    display: unset;
  }

  .name-display {
    font-size: 80px;
    margin-left: 0;
  }

  .header__socials, .scroll__down {
    display: none;
  }

}

@media screen and (max-width: 400px) {
  header {
    /* height: 40vh; */
    width: 90%;
    display: unset;
  }

  .name-display {
    font-size: 55px;
    margin-left: 0;
  }

  .header__socials, .scroll__down {
    display: none;
  }

}