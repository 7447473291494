.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1.3;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  display: grid;
  place-items: center;
  margin-bottom: 2rem;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(6deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0deg);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 45px;
  font-family: 'Montserrat', sans-serif;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 10px;
  text-align: center;
  transition: var(--transition);
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 3px;
}

.about__card h5 {
  font-size: 0.95rem;
  font-weight: 200;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-grey);
}

.about__content p {
  display: flex;
  margin: -5px 0 2.6rem;
  color: var(--color-grey);
  font-size: 13.7px;
  text-align: center;
}

.btn-about {
  text-align: center;
}


/* ============== MEDIA QUERIES (MED) ==============  */
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.3rem;
  }
}

/* ============== MEDIA QUERIES (SM) ==============  */
@media screen and (max-width: 600px) {
  #about {
    display: unset;
  }
  
  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__content {
    /* text-align: center; */
    /* justify-content: center; */
    margin-left: 20px;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}

